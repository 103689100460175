import { mapActions, mapGetters } from 'vuex'

const Modal = () => import(/* webpackChunkName = "c-modal" */'@/components/Modal')
const emptyField = (name) => `${name} tidak boleh kosong`

export default {
  name: 'AddDonationForm',
  Components: {
    Modal
  },
  props: {
    donationData: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      title: '',
      chooseDate: '',
      summary: '',
      imageUrl: '',
      donationContents: '',
      commission: '',
      dateInformation: '',
      meetingId: '',
      password: '',
      time: '',
      serviceType: '',
      serviceValue: '',
      isEdit: false,
      errors: []
    }
  },
  created () {
    this.initPage()
  },
  computed: {
    ...mapGetters('user', ['token']),
    requestData () {
      const dateInMillis = new Date(this.chooseDate).getTime()
      return {
        id: this.donationData.id,
        donation: {
          contents: this.donationContents,
          date: dateInMillis,
          imageUrl: this.imageUrl,
          summary: this.summary,
          title: this.title,
          token: this.token,
          information: {
            commission: this.commission,
            dateInformation: this.dateInformation,
            meetingId: this.meetingId,
            password: this.password,
            time: this.time,
            serviceType: this.serviceType,
            serviceValue: this.serviceValue
          }
        }
      }
    },
    errorsText () {
      return this.errors.join(', ')
    }
  },
  methods: {
    ...mapActions('donation', ['createDonationAction', 'editDonationAction', 'getDonationAction']),
    close () {
      this.$emit('close')
    },
    initPage () {
      if (Object.keys(this.donationData).length > 0) {
        const { title, summary, imageUrl, contents, date, information } = this.donationData
        const isoDate = new Date(date).toISOString()
        const { commission, dateInformation, meetingId, password, time, serviceValue, serviceType } = information
        this.title = title
        this.summary = summary
        this.imageUrl = imageUrl
        this.donationContents = contents
        this.isEdit = true
        this.chooseDate = isoDate
        this.commission = commission
        this.dateInformation = dateInformation
        this.meetingId = meetingId
        this.password = password
        this.time = time
        this.serviceValue = serviceValue
        this.serviceType = serviceType
      }
    },
    handleSubmit () {
      this.validateForm()
      if (this.errors.length === 0) {
        if (this.isEdit) {
          this.editDonationAction({
            data: this.requestData,
            success: this.handleSuccessSubmit
          })
        } else {
          this.createDonationAction({
            data: this.requestData,
            success: this.handleSuccessSubmit
          })
        }
      }
    },
    handleSuccessSubmit () {
      this.$swal({
        icon: 'success',
        title: 'Success Submit',
        showConfirmButton: false,
        timer: 1500
      })
      this.getDonationAction({
        success: () => {}
      })
      this.close()
    },
    validateForm () {
      this.errors = []
      if (this.title === '') this.errors.push(emptyField('Judul'))
      if (this.chooseDate === '') this.errors.push(emptyField('Tanggal'))
      if (this.summary === '') this.errors.push(emptyField('Summary'))
      if (this.imageUrl === '') this.errors.push(emptyField('Alamat Gambar'))
      if (this.contents === '') this.errors.push(emptyField('Isi'))
      if (this.commission === '') this.errors.push(emptyField('komisi'))
    }
  }
}
